import React from "react";
import { Container, Row } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import CatalogoPromos from "../../components/layouts/CatalogoPromos";

import Layout from "../../components/layouts/layout";
import SEO from "../../components/seo";

export default function Promociones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem={"promo-accesorios"} parameters={parameters}>
            <SEO
                title="Accesorios Chevrolet, GM, Buick, Cadillac, Corvette - Autos seminuevos"
                description="Accesorios Chevrolet Originales, GM, Buick, Cadillac, Corvette - Accesorios Aveo, Spark, Onix, beat, Pickup - Camara reversa, alarma, estereo, portabicicletas, estribos"
                keywords="Accesorios Chevrolet"
            />
            <Container>
                <Row className="pb-3">
                    <img
                        src="https://d3s2hob8w3xwk8.cloudfront.net/banners/banner-promociones-accesorios-chevrolet-rivero.jpg"
                        style={{ width: "100%" }}
                        alt="banner accesorios"
                    />
                        
                </Row>
 
                <CatalogoPromos promoType="accesorios" props={props}/>

                <Row className="pt-3 pb-3">
                    <h2 style={{ fontSize: 12 }}>
                        Los mejores accesorios Chevrolet tu auto
                    </h2>
                </Row>
            </Container>
        </Layout>
    )

}